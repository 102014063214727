import React from 'react'
import Layout from '../components/layout'
import VirtualAssistant from '../components/categories/virtual-assistant'
import Seo from '../components/seo'

const VirtualAssistantPage = ({ location }) => {
  return (
    <Layout location={location}>
      <VirtualAssistant />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Virtual Assistant Freelancers | Codestaff'
  const description =
    'Hire the best Virtual Assistant freelancers at Codestaff. Get the top 1% of Virtual Assistant professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default VirtualAssistantPage
