import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is A Virtual Assistant?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A VA or virtual assistant is a professional that provides administrative, project management, marketing, technical and creative support to entrepreneurs, business owners and other busy professionals."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Are The Benefits Of Using A Virtual Assistant?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "With a Codestaff virtual assistant, you get instant support from a highly skilled professional when you need the services. You don’t need to hire a virtual assistant permanently as you can hire them on a part-time basis"
        }
    }
}

const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Virtual Assistant Services",
    "image": "https://codestaff.io/virtual-assistant.jpg",
    "description": "Hire a Remote Virtual Assistant to help your company with Data Entry, Web Development, Excel, WordPress or other office tasks. Virtual Assistant Salaries are Negotiable.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/virtual-assistant",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "David Alleva",
        "reviewBody": "Great Experience!",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "David Alleva" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}

const MainWrapper = styled.div`
margin: 0;
`

const VirtualAssistant = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Virtual Assistants.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Virtual Assistants. Top
                                    companies and start-ups choose Codestaff professional Virtual
                                    Assistants for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Virtual Assistant now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE VIRTUAL ASSISTANTS' banner='/virtual-assistant.png' bannerAlt='virtual assistant banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default VirtualAssistant